<template>
  <div class="container">
    <!-- <van-nav-bar left-arrow title="编辑资料" right-text="保存"  @click-right="saveUser" @click-left="$router.back()"></van-nav-bar> -->
    <van-nav-bar title="绑定微信" left-arrow @click-left="$router.back()" />
    <div style="text-align: center; margin: 100px 2px">
      <h2>绑定微信可以，接受微信推送信息，微信中可以直登录</h2>
    </div>
    <div class="btn-box">
      <van-button
        type="info"
        size="large"
        round
        block
        @click="bindwx"
        :loading="isloading"
        :disabled="isloading"
        v-if="appid != '' || isloading"
        loading-text="加载中..."
        >点击重试</van-button
      >

      <van-button
        type="danger"
        size="large"
        block
        v-if="appid == '' && !isloading"
        >获取微信绑定信息异常,请联系管理员</van-button
      >
    </div>
  </div>
</template>
  
  <script>
//import { changePassWord } from "@/api/user";
import { GetAppId } from "@/api/wxuser";
import { Toast } from "vant";

export default {
  name: "user-BindWxinPre",
  data() {
    return {
      appid: "",
      isloading: true,
    };
  },
  created() {
    this.GetAppId();
  },
  methods: {
    async GetAppId() {
      try {
        const data = await GetAppId();
        if (data.data.success) {
          this.appid = data.data.data;
          this.bindwx();
        } else {
          Toast(data.data.errorMessage);
        }
      } catch (error) {
        console.log(error);
      }
    },

    bindwx() {
      //this.$router.push('/user/bindwx?code=123code123');
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        this.appid +
        "&redirect_uri=https%3a%2f%2fkm.bjznpz.com%2f%23%2fuser%2fbindwx&response_type=code&scope=snsapi_userinfo#wechat_redirect";
    },
  },
};
</script>
  
  <style lang="less" scoped>
.btn-box {
  padding: 20px;
}
</style>
  